








import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
})
export default class TheFooter extends Vue {

}

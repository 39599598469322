import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import MediaCenterAPI, {SessionSystem} from '@/services/MediaCenterAPI';
import IoC from '@rawlplug/inversion-of-control/src/IoC';

const ioc = new IoC();
ioc.set('service.session_system', SessionSystem, {args: [router]});
ioc.set('service.media_center', MediaCenterAPI, {args: [process.env.VUE_APP_HOST_PROTOCOL, process.env.VUE_APP_HOSTNAME, 'a09oBf1A8CFC11fe0', router]});

Vue.config.productionTip = false;
Vue.prototype.$packageVersion = require('../package.json').version;
Vue.prototype.$ioc = ioc;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');

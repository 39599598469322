















import {Component, Vue, Watch} from "vue-property-decorator";
import TheHeader from './components/TheHeader.vue';
import TheSite from './components/TheSite/TheSite.vue';
import TheTopBar from './components/TheTopBar/TheTopBar.vue';
import TheFooter from './components/TheFooter/TheFooter.vue';
import TheMenu from './components/TheMenu/TheMenu.vue';
import RegisterComponentInterface, {RegisterComponentRecord, RegisterComponent} from "@/components/TheSite/RegisterComponentInterface";
import menuStructure, {MenuSHowStatusEnum} from './resources/menu';
import getCookie from '@/resources/cookie';

@Component({
  components: {
    TheHeader,
    TheSite,
    TheTopBar,
    TheFooter,
  },
})
export default class App extends Vue {
  constructor() {
    super();
  }

  mounted(): void {
    const register: RegisterComponentInterface = (this.$refs.theSite) as unknown as RegisterComponentInterface;
    const r1: RegisterComponentRecord = {} as RegisterComponentRecord;
    r1.name = "TheMenu";
    r1.component = TheMenu as unknown as RegisterComponent;
    register.registerComponent(r1);
  }
}










import {Component, Vue} from "vue-property-decorator";
import LanguageSwitcher from "./LanguageSwitcher.vue";

@Component({
  components: {
    LanguageSwitcher
  },
  props: ["text"]
})
export default class TheHeader extends Vue {
}

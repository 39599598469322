











import {Component, Vue} from 'vue-property-decorator';
import FileRecordFormat from '@/resources/FileRecordFormat';
import bytesToSize from '@/resources/bytesToSize';

@Component({
  components: {
  },
  props: {
    tagName: {
      default: "div",
      type: String
    },
    record: {
      type: Object,
      required: true
    }
  }
})
export default class SimpleRecord extends Vue{
  prepareToSize(size: number) {
    return bytesToSize(size)
  }
}

const statusCodePrepare = (codePattern, fn) => {
    let type = '';
    if (typeof codePattern === 'object') {
        if (codePattern.test) {
            type = 'regex';
        }
        else if (codePattern.min) {
            type = 'min_max';
        }
        else if (codePattern.length) {
            type = 'array';
        }
    }
    else if (typeof codePattern === 'number') {
        type = 'number';
    }
    return {
        type,
        pattern: codePattern,
        callBack: fn,
    };
};
export default class ResponseStatusCodeManager {
    constructor() {
        this.codeRange = [];
        this.catchFunction = null;
        // eslint-disable-next-line no-unused-vars
        this.errorFunction = (data) => { };
    }
    /**
     * @param codePattern
     * @param fn
     */
    register(codePattern, fn) {
        this.codeRange.push(statusCodePrepare(codePattern, fn));
        return this;
    }
    /**
     * @param data
     * @param code
     */
    call(data, code) {
        this.codeRange.forEach((codeData) => {
            if (ResponseStatusCodeManager.isMatch(codeData, code)) {
                codeData.callBack(data, code);
            }
            else {
                const extension = data;
                if (this.catchFunction) {
                    this.catchFunction(extension);
                    return;
                }
                throw extension;
            }
        });
    }
    catch(catchFunction) {
        this.catchFunction = catchFunction;
    }
    fuck(catchFunction) {
        this.errorFunction = catchFunction;
    }
    sendFuck(exception) {
        this.errorFunction(exception);
    }
    /**
     * @param codeData
     * @param code
     */
    static isMatch(codeData, code) {
        let isOk = false;
        let pattern = null;
        // eslint-disable-next-line default-case
        switch (codeData.type) {
            case 'number':
                isOk = codeData.pattern === code;
                break;
            case 'min_max':
                pattern = codeData.pattern;
                pattern.max = pattern.max ? pattern.max : code;
                isOk = pattern.min <= code && code <= pattern.max;
                break;
            case 'regex':
                pattern = codeData.pattern;
                isOk = pattern.test(code);
                break;
            case 'array':
                pattern = codeData.pattern;
                isOk = typeof pattern.find((value) => value === code) === 'undefined';
                break;
        }
        return isOk;
    }
}

import Home from '../views/Home.vue';
import Packages from '../views/Packages.vue';
import getCookie from './cookie';
import {Route} from 'vue-router';

enum MenuSHowStatusEnum {
  ALWAYS = 1,
  LOGIN = 2,
  LOGOUT = 4,
}

const menu = [
  {
    name: 'Admin',
    show: MenuSHowStatusEnum.ALWAYS,
    items: [
      {
        name: 'index',
        label: 'Index',
        path: '/',
        meta: {
          handle: 'home',
          show: MenuSHowStatusEnum.ALWAYS,
        },
        component: Home,
      }, {
        name: 'spaces',
        label: 'Spaces',
        path: '/spaces',
        meta: {
          handle: 'space',
          show: MenuSHowStatusEnum.LOGIN,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Space/Spaces.vue'),
      }, {
        name: 'packages',
        label: 'Packages',
        path: '/packages',
        meta: {
          handle: 'package',
          show: MenuSHowStatusEnum.LOGIN,
        },
        component: Packages,
      }, {
        name: 'logout',
        label: 'Logout',
        path: '/logout',
        meta: {
          handle: 'logout',
          show: MenuSHowStatusEnum.LOGIN,
        },
        beforeEnter: (to: Route, from: Route, next: (config: object) => {}) => {
          document.cookie = `rid_token=; path=/;  max-age=${-1};`;
          document.cookie = `is_login=; path=/;  max-age=${-1};`;
          next({ name: 'index' });
        },
        component: () => {
        },
      },
    ],
    itemsHidden: [
      {
        path: '/spaces/:spaceName([^/]+)',
        meta: {
          handle: 'space',
          show: MenuSHowStatusEnum.LOGIN,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Space/SpacesRecord.vue')
      },
      {
        path: '/spaces/:spaceName([^/]+)/:hashName([^/]+)',
        meta: {
          handle: 'space',
          show: MenuSHowStatusEnum.LOGIN,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Space/SpacesRecord.vue')
      },
      {
        path: '/package/:packageName([^/]+)/:packageHashName([^/]+)',
        meta: {
          handle: 'package',
          show: MenuSHowStatusEnum.ALWAYS,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/PackagesRecord.vue')
      },
    ],
  },
  {
    name: 'Your Account',
    show: MenuSHowStatusEnum.LOGIN,
    items: [
      {
        name: 'shared_for_me',
        label: 'Shared for me',
        path: '/shared_for_me',
        meta: {
          handle: 'shared_for_me',
          show: MenuSHowStatusEnum.LOGIN,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/Shared/SharedForMe.vue'),
      },
      {
        name: 'your_data',
        label: 'Your Data',
        path: '/your_data',
        meta: {
          handle: 'your_data',
          show: MenuSHowStatusEnum.LOGIN,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/YourData.vue'),
      },
      {
        name: 'your_agreements',
        label: 'Your agreements',
        path: '/your_agreements',
        meta: {
          handle: 'your_agreements',
          show: MenuSHowStatusEnum.LOGIN,
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/YourAgreements.vue'),
      },
    ],
  },
];

// @ts-ignore
const cookie = getCookie('is_login');
let filterByStatus = MenuSHowStatusEnum.LOGOUT;
if (cookie === undefined) {
  filterByStatus = MenuSHowStatusEnum.LOGIN;
}

export default menu;
export {
  MenuSHowStatusEnum
};

















// @ts-nocheck
import {Component, Watch, Vue} from "vue-property-decorator";
import {RegisterComponent} from "../TheSite/RegisterComponentInterface";
import menuStructure, {MenuSHowStatusEnum} from "../../resources/menu";
import getCookie from "@/resources/cookie";

@Component({
  components: {},
})
export default class TheMenu extends Vue implements RegisterComponent {
  protected menuStructure = menuStructure;
  protected matchRouter: any = "";
  protected filterByStatus = MenuSHowStatusEnum.ALWAYS;

  @Watch('$route', { immediate: true, deep: true })
  setMatchRouter() {
    if (this.$route.matched.length) {
      this.matchRouter = this.$route.matched[0];
    }
  }
  @Watch("$route", { immediate: true, deep: true })
  setFilterStatus() {
    const cookie = getCookie("is_login");
    this.filterByStatus = MenuSHowStatusEnum.LOGIN | MenuSHowStatusEnum.ALWAYS;
    if (cookie === undefined) {
      this.filterByStatus = MenuSHowStatusEnum.LOGOUT | MenuSHowStatusEnum.ALWAYS;
    }
  }

  isSelected(site: any) {
    return this.matchRouter?.meta?.handle === site.meta?.handle;
  }
}

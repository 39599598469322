



















// @ts-nocheck
import {Component, Vue} from "vue-property-decorator";
import SimpleRecord from "@/components/Record/SimpleRecord.vue";

@Component({
  components: {
    SimpleRecord
  },
})
export default class Packages extends Vue {
  private packages: Array<object> = [];
  private packagesShared: Array<object> = [];

  mounted(): void {
    this.$ioc.get("service.media_center").packagesList().then(
      (result: any) => {
        this.packages = result.body.packages.map(
          (packageItem: any) => Object.assign(packageItem, { path: `/package/${packageItem.name}/${packageItem.hashName}` })
        );
      }
    );
    this.$ioc.get("service.media_center").packagesSharedList().then(
      (result: any) => {
        this.packagesShared = result.body.packagesShared.map(
          (packageItem: any) => Object.assign(packageItem, { path: `/package/${packageItem.name}/${packageItem.hashName}` })
        );
      }
    );
  }
}

import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import menuStructure, {MenuSHowStatusEnum} from '../resources/menu';
import getCookie from '@/resources/cookie';

Vue.use(VueRouter);
let routes: RouteConfig[] = [];
menuStructure.forEach(
  (menuStructure) => {
    // @ts-ignore
    routes = routes.concat(menuStructure.items);
    if (menuStructure.itemsHidden && menuStructure.itemsHidden.length) {
      // @ts-ignore
      routes = routes.concat(menuStructure.itemsHidden);
    }
  },
);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(
  (to, from, next) => {
    // @ts-ignore
    const cookie = getCookie('is_login');
    // tslint:disable-next-line:no-bitwise
    let filterByStatus = (MenuSHowStatusEnum.LOGIN | MenuSHowStatusEnum.ALWAYS);
    if (cookie === undefined) {
      // tslint:disable-next-line:no-bitwise
      filterByStatus = (MenuSHowStatusEnum.LOGOUT | MenuSHowStatusEnum.ALWAYS);
    }
    let params = {};
    // tslint:disable-next-line:no-bitwise
    if (!(to.meta?.show & filterByStatus)) {
      params = { path: '/' };
    }
    next(params);
  },
);

export default router;

// @ts-ignore
import http from 'http';
// @ts-ignore
import https from 'https';
import {Vue} from 'vue-property-decorator';
// @ts-ignore
import ResponseStatusCodeManager from '@/resources/ResponseStatusCodeManager.js';
import getCookie from '@/resources/cookie';

let httpInstance = http;
let userToken = '';
let siteProtocol = '';
let siteHostName = '';
let siteApiHost = '';
let Authorization = getCookie('rid_token', '') as string;
let usedRouter: any;


let loginError = () => {
  document.cookie = `rid_token=; path=/;  max-age=${-1};`;
  usedRouter.push('/permission-denied');
};
let loginSuccess = () => {
};
const permissionResStaCodeManager = new ResponseStatusCodeManager();
const sessionResStaCodeManager = new ResponseStatusCodeManager();
let successSessionCall = (data: any, statusCode: any) => {
};
sessionResStaCodeManager
  .register(200, (data: any, statusCode: any) => {
    successSessionCall(data, statusCode);
  })
  .catch((exception: any) => {
  });
permissionResStaCodeManager.register(401, (data: any, statusCode: any) => {
  loginError();
}).catch((exception: any) => {
  loginSuccess();
});


class SessionSystem {
  constructor(protected router: any) {
  }

  public login(user: string, password: string) {
    const body = {
      login: user,
      password
    };
    fromServerPost('https://auth0-logs.rawl-app.com', '/login.php', JSON.stringify(body)).then(
      (result) => {

        successSessionCall = (data: any, statusCode: any) => {
          data.json().then((dataRecord: any) => {
            document.cookie = `rid_token=${dataRecord.body.key}; path=/;  max-age=${60 * 60 * 24 * 14};`;
            document.cookie = `is_login=true; path=/;  max-age=${60 * 60 * 24 * 14};`;
            if (dataRecord?.body?.key) {
              Authorization = 'Bearer ' + dataRecord.body.key;
              this.router.push({ path: '/spaces' });
            }
          });
        };
        sessionResStaCodeManager.call(result, result.status);
      },
    );
  }
}

export default class MediaCenterAPI {
  private apiHost = '';

  constructor(private protocol: string, private hostName: string, private token: string, protected router: any) {
    this.apiHost = `${protocol}://${hostName}`;
    if (protocol === 'https') {
      httpInstance = https;
    }
    userToken = token;
    siteProtocol = protocol;
    siteHostName = hostName;
    siteApiHost = this.apiHost;
    usedRouter = router;
  }

  public packagesList(): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/packages'));
  }

  public package(hashName: string): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, `/api/package/${hashName}/files`));
  }

  public packagesSharedList(): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/packages-shared'));
  }

  public packagesSharedAllList(): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/packages-shared/all'));
  }

  public spacesList(): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/spaces'));
  }

  public spaceFiles(spaceName: string, dirHashName: string | undefined): Promise<object> {
    let url = `/api/folder/${spaceName}`;
    if (dirHashName) {
      url += `/${dirHashName}`;
    }

    return permissionValidate(fromServerGet(this.hostName, url));
  }

  public deleteFileFormSpace(spaceName: string, fileHashName: string): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, `/${spaceName}/delete-file/${fileHashName}`));
  }

  public login(): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/login'));
  }

  public setSession(token: string): Promise<object> {
    return permissionValidate(fromServerGet(this.hostName, '/api/set-session'));
  }

  public saveFilesToPackage(packageName: string, body: string): Promise<Response> {
    return permissionValidate(fromServerPut(this.apiHost, `/api/package/${packageName}/files`, body));
  }

  public spacesListFromCache(): Promise<object> {
    if (!Vue.prototype.$store) {
      Vue.prototype.$store = this.spacesList();
    }
    return Vue.prototype.$store;
  }

  public foldersList(spaceName: string, folderHash: string): Promise<object> {
    let url = `/api/folder/${spaceName}`;
    if (folderHash) {
      url += '/' + folderHash;
    }
    return permissionValidate(fromServerGet(this.hostName, url));
  }
}

function permissionValidate(response: Promise<Response> | Promise<object>): Promise<Response> {
  return new Promise((resolve, reject) => {
    // @ts-ignore
    response.then(
      (res: any) => {
        const statusCode = res.statusCode ? res.statusCode : res.status;
        const result = res.statusCode ? JSON.parse(res.body) : res;
        loginSuccess = () => {
          resolve(result);
        };
        permissionResStaCodeManager.call(result, statusCode);
      },
    );
  });
}

function fromServerGet(hostname: string, path: string): Promise<object> {
  return (async (): Promise<object> => {
    return new Promise(async (resolve, reject) => {
      const options = {
        hostname,
        path,
        crossDomain: true,
        xhrFields: {
          withCredentials: false,
        },
        headers: {
          'Authorization': Authorization,
        },
      };

      await httpInstance.get(
        options,
        (res: any) => {
          let body = '';
          res.on('data', (data: string) => {
            body += data;
          });
          res.on('end', () => {
            resolve(Object.assign({ body }, res));
          });
        },
      );
    });
  })();
}


function fromServerGetFetch(hostname: string, path: string): Promise<Response> {
  return fetch(hostname + path,
    {
      // mode: 'cors',
      method: 'GET',
      headers: new Headers(
        {
          'Authorization': Authorization,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      ),
    },
  );
}

function fromServerPut(hostname: string, path: string, body: string): Promise<Response> {
  return fetch(hostname + path,
    {
      // mode: 'cors',
      method: 'PUT',
      body,
      headers: new Headers(
        {
          'Authorization': Authorization,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      ),
    },
  );
}

function fromServerPost(hostname: string, path: string, body: any): Promise<Response> {
  let headerOptions = {
    'Authorization': Authorization,
    'Accept': 'application/json',
  };
  if (typeof body === 'string') {
    // @ts-ignore
    headerOptions['Content-Type'] = 'application/json';
  }
  return fetch(hostname + path,
    {
      // mode: 'cors',
      method: 'POST',
      body,
      headers: new Headers(headerOptions),
    },
  );
}


function parseResponse(responseStatusCodeManager: ResponseStatusCodeManager, data: any, callBack: any, callBackError: any) {
  responseStatusCodeManager.register(201, (data: any, statusCode: any) => {
    callBack();
  }).catch((exception: any) => {
    callBackError(exception);
  });
  responseStatusCodeManager.call(data, data.status);
}

const FileUploader = function(options: any) {
  let settings: any = {
    done: function(data: any) {
    },
    error: function(data: any) {
    },
    progress: function(data: any) {
    },
    url: '',
  };
  //@ts-ignore
  settings = $.extend(settings, options);

  return {
    sendFile: async function(file: any) {
      const formData = new FormData();
      formData.append('attach[]', file);
      //@ts-ignore
      window.formData = formData;

      const responseStatusCodeManager = new ResponseStatusCodeManager();
      const data = await fromServerPost(siteApiHost, `/${options.url}`, formData)
      // tslint:disable-next-line:no-shadowed-variable
        .then(async (data: any) => {
          parseResponse(responseStatusCodeManager, data, () => {
              settings.done(data);
            }, (exception: any) => {
              settings.error(exception);
            },
          );
        }).catch((e) => {
          // thsi error is unknow - domain, connection, wrong response from server.
          settings.error(e);
        });
    },
  };
};

function spaceUploadFile(spaceName: string, options: any) {
  options.url = `${spaceName}/upload-file`;
  // @ts-ignore
  return new FileUploader(options);
}

export {
  spaceUploadFile,
  SessionSystem
};

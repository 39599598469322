







































// @ts-nocheck
// @ is an alias to /src

import {Component, Vue} from "vue-property-decorator";
import SimpleRecord from "@/components/Record/SimpleRecord.vue";
import FileRecordFormat from '@/resources/FileRecordFormat';

@Component({
  components: {
    SimpleRecord
  },
})
export default class Home extends Vue {
  private publicPackages: FileRecordFormat[] = [];
  private user = { login: "", password: "" };

  private loginAction(event: any) {
    event.preventDefault();
    const sessionSystem: SessionSystem = this.$ioc.get("service.session_system") as SessionSystem;
    sessionSystem.login(this.user.login, this.user.password);
    console.log("login action", this.user);

    return false;
  }

  mounted(): void {
    this.$ioc.get("service.media_center").packagesSharedAllList().then(
      (result: any) => {
        this.publicPackages = result.body.packagesShared.map(
          (packageItem: any) => Object.assign(packageItem, { path: `/package/${packageItem.name}/${packageItem.hashName}` })
        );
      }
    );
  }
}

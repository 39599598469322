





















// @ts-nocheck
import {Component, Watch, Vue} from "vue-property-decorator";
import RegisterComponentInterface, {RegisterComponentRecord} from "./RegisterComponentInterface";
import InternetStatusListener from "@/resources/InternetStatusListener";
import TheNetStatus from "@/components/TheNetStatus";

@Component({
  components: {
    TheNetStatus
  },
})
export default class TheSite extends Vue implements RegisterComponentInterface{
  private color = "transparent";
  private queryParams: Array<any> = [];
  protected components: Array<object> = [];
  protected breadCrampMenu: any[] = [];
  protected isOnline = true;
  protected homePath: any = {
    fileName: 'index',
    hashName: "",
    path: "/"
  };
  protected spaceName = '';

  constructor() {
    super();
  }

  public setBGColor(color: string) {
    this.color = `background-color: ${color}`;
  }

  @Watch('$route', { immediate: true, deep: true })
  changeBgColor() {
    if (this.$route.matched.length) {
      this.queryParams = this.$route.params;
      this.setBGColor('transparent');
      this.homePath = {
        fileName: 'index',
        hashName: "",
        path: "/"
      };
      this.breadCrampMenu = [];
    }
  }

  mounted(): void {
    Vue.prototype.$ioc.set('service.bread-cramp-update', () => (homePath: any, breadCrampMenu: any[]) => {
      this.homePath = homePath;
      this.breadCrampMenu = breadCrampMenu;
    }, {});

    new InternetStatusListener(() => {this.isOnline = true;}, () => {this.isOnline = false;});
  }

  public registerComponent(component: RegisterComponentRecord): void {
    this.components.push(component);
  }
}
